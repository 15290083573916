import React, { Component } from 'react';
import './Main.css';

export default class App extends Component {
    render() {
        return (
            <div class="site-container">
                <div class="header"></div>
                <div class="main-content">
                    <p>Collage Kid Experimental Filmmaker is designed to help you create video art
                        pieces from what you discover around you.  After you have gathered video, images, and sounds,
                        Experimental Filmmaker will create and execute edits based on weather, stock market, and
                        planetary alignment data. While at times chaotic, the results are not random, but rather a jumbled
                        reflection of the moment of creation.</p>
                    <p>Generated films can be cycled through the editor any number of times along with whatever combination
                    of previous results and gathered media you desire.  The narration feature allows you to layer an unlimited number of sound recordings in your piece, which can then be re-edited
                    as many times as you like.  This workflow allows you to recursively shape the feel and texture of your piece while at the same time generating fresh, surprising results every pass through the editor.</p>
                    <p>For more information, please contact the <a href="mailto:collagekidmedia.com">Collage Kid team</a>.</p>
                </div>
            </div>

    );
    }
}
